<script lang="ts" context="module">
  import { onMount } from 'svelte'
  import { writable } from 'svelte/store'
  let hoverId = writable('')
</script>

<script lang="ts">
  import { Button, Loading, Popover } from 'carbon-components-svelte'
  import Close16 from 'carbon-icons-svelte/lib/Close.svelte'

  import { ellipsize, stripHTML } from 'common/src/utils'

  import { state } from '../../../store/state'
  import IconSelector from '../../sidebar/icon-selector.svelte'

  import { drop, close } from './tab'

  export let props: {
    contentId: string
    contentType: string
    iconId: string | null
    btnText: string | null
  }

  const { pinned, handleClose } = state.rightPanel

  const tabs = state.tabs
  const uiComponentId = state.uiComponentId

  let selected = tabs.selected
  let isLoading = tabs.isLoading
  let hideTooltip = false

  const onDragStart = (e: DragEvent) => {
    if (e.dataTransfer) {
      e.dataTransfer.effectAllowed = 'move'
      e.dataTransfer.setData('application/json', JSON.stringify({ contentId: props.contentId }))
      hideTooltip = true
    }
  }

  const onDragEnter = () => {
    hoverId.set(props.contentId)
  }

  const onDragEnd = () => {
    hoverId.set('')
    hideTooltip = false
  }

  const onDrop = (e: any) => {
    const data = JSON.parse(e.dataTransfer.getData('application/json'))
    drop(data, props, tabs)
  }

  const onSelect = () => {
    tabs.selectTab(props.contentId, props.contentType)
  }

  const onClose = () => {
    state.smartTagsManager.removeKey(props.contentId)
    close(props.contentId, state)
    pinned.set(false)
    handleClose()
  }

  $: isSelected = $selected?.contentId === props.contentId
  $: isInEditor = $uiComponentId === 'editor-shell'

  let isInEdit = false
  const unsubscribe = state.locks.subscribe(() => {
    isInEdit = state.contentIsInEdit(props.contentId)
  })

  onMount(() => {
    return () => {
      unsubscribe()
    }
  })
</script>

<div
  class:hovering={props.contentId === $hoverId}
  class="tab-container flex flex-row"
  draggable={true}
  class:active={isSelected && isInEditor}
>
  <!-- svelte-ignore a11y-no-static-element-interactions -->
  <div
    on:dragstart={onDragStart}
    on:dragenter|preventDefault={onDragEnter}
    on:dragover|preventDefault={() => false}
    on:dragend|preventDefault={onDragEnd}
    on:drop={onDrop}
    draggable={true}
    class="tab"
  >
    <Button class="btn-tab btn-text" kind="primary">
      <button class="flex flex-row justify-between w-full bg-transparent" on:click={onSelect}>
        <div class="flex flex-row items-center">
          <div class="w-6">
            {#if !$isLoading?.includes(props.contentId)}
              <IconSelector iconId={props.iconId} />
            {:else}
              <Loading withOverlay={false} small />
            {/if}
          </div>
          <span class="text-s ml-2">
            {ellipsize(stripHTML(props.btnText || ''))}
            {#if isInEdit}*{/if}
          </span>
        </div>
      </button>
      <Button class="btn-action" kind="ghost" icon={Close16} on:click={onClose} tooltipPosition="left" />
      <div class="invisible popover-container" class:hide-tooltip={hideTooltip}>
        <Popover open caret align="bottom">{stripHTML(props.btnText || '')}</Popover>
      </div>
    </Button>
  </div>
</div>

<style lang="postcss">
  .tab-container {
    height: calc(100% - var(--topbar-margin));
  }

  .tab :global(.bx--btn--primary) {
    @apply text-white border-none pr-1;
    margin-right: var(--topbar-margin);
    max-height: calc(var(--bars-size) - var(--topbar-margin));
    min-height: calc(var(--bars-size) - var(--topbar-margin));
    background: transparent;
    min-width: 12rem;
    border-radius: 0;
    outline: none;
    box-shadow: none;
  }

  .tab-container:not(.active) :global(.bx--btn--primary) {
    --cds-icon-01: white;
    --svg-stroke: white;
    --svg-fill: white;
    border-color: white;
    border-style: solid;
    border-width: 0;
  }

  .active :global(.bx--btn--primary.btn-text) {
    @apply text-black rounded-t-custom;
    background-color: var(--background);
  }

  .tab-container:first-of-type:not(.active) :global(.bx--btn--primary),
  :global(.tab-container:not(.active)) + .tab-container:not(.active) :global(.bx--btn--primary) {
    @apply border-l;
  }
  :global(.new-content-tab-container.active) + .tab-container:not(.active) :global(.bx--btn--primary) {
    @apply border-l-0;
  }
  .tab-container:last-child:not(.active) :global(.bx--btn--primary) {
    @apply border-r;
  }

  .tab :global(.bx--btn--primary.btn-text) {
    @apply pl-2 pt-0 pb-0;
  }

  .tab :global(.bx--btn--primary.btn-action) {
    @apply pl-1;
  }

  .tab :global(.bx--btn--primary.btn-action:before) {
    border-style: none;
    outline: none;
  }

  .tab :global(.btn-text:hover .btn-action),
  .active .tab :global(.btn-action) {
    @apply visible;
  }

  .tab :global(.bx--btn) {
    border-width: 0;
    outline-width: 0;
    min-height: 100%;
    max-height: 100%;
  }

  .tab :global(.btn-action) {
    @apply invisible;
  }

  .tab :global(.bx--btn--ghost.btn-action) {
    @apply p-1;
  }

  .tab :global(.bx--btn--ghost.btn-action:hover) {
    @apply bg-transparent;
  }

  .tab :global(.btn-action .bx--assistive-text) {
    @apply invisible;
  }

  :global(.btn-text:hover) .popover-container {
    transition-delay: 1s;
    @apply visible;
  }

  .tab :global(.hide-tooltip) {
    display: none;
  }

  .tab :global(.bx--popover-contents) {
    @apply p-1 text-white;
    background-color: var(--reverse-background);
  }

  .tab :global(.bx--btn--ghost.btn-action:hover::before) {
    border-style: none;
    outline: none;
  }

  .hovering {
    border-style: dashed;
    border-width: 0rem 0rem 0rem 0.25rem;
    border-color: var(--btn-primary);
  }
</style>
